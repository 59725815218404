import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Modal, Button} from 'antd';
import common from '../utils/common';
import $ from 'jquery';
import '../../node_modules/antd/dist/antd.css';
import './basic.css';
import Headportrait from '../image/Headportrait.png';
import del from '../image/delete.png';
import img from '../image/emptymessage.png';
import picture from '../image/picture.png';
import search from '../image/search.png';
import setting from '../image/setup.png';
import remove from '../image/delete.png';
import axios from 'axios';
import ReconnectingWebSocket from 'reconnecting-websocket';
import Editor from 'wangeditor';

let user;
let ws;
let pre_good;//整个商品的信息
let limit = 50;
let page = 1;
let now_item = '';//当前联系人信息
const options = {
    connectionTimeout: 1000,
    maxRetries: 100,
};
let text='';
class App extends Component {
    state = {
        visible: false,//昵称的modal
        price: '',//输入的价格
        chatlist: [],//聊天列表
        lowestprice: '',//最低价
        data: [],//聊天记录
        textareaValue: '',//发送的内容
        kh_id: '',//全局的客户id
        keyword: '',
        ischat: true,//判断当前是否能发消息给该用户
        bgcolor: 'item',//联系人的背景色
        customer: [],//查询用户结果
        show_search: false,
        more_text: '点击加载更多...',
        last_new_time: '',
        quick_delte: false,
    };
    visible = false;
    async componentWillMount() {
        user = JSON.parse(localStorage.getItem('user'));
        await this.getChatListData();
        // this.editor()
    }
    didObj2Str(obj) {
        let _xx = '';
        for (let key in obj) {
            _xx += key + '=' + obj[key] + '&';
        }
        // console.log(_xx);
        return _xx;
    }

    componentDidMount() {
        ws = new ReconnectingWebSocket('wss://api.zishahui.vip/wss', [], options);
        ws.addEventListener('open', () => {
            // rws.send('hello!');
            // console.log('连接成功')
        });
        ws.addEventListener('message', (e) => {
            let self = this;
            let result = JSON.parse(e.data);
            if (result.code == 0) {//等于0表示连接成功
                axios.post('https://api.zishahui.vip/user/online', {
                    user_id: user.id,
                    token: user.token,
                    fd: result.fd,
                    link_place:1,
                })
                .then((res) => {
                    if (res.data.code === 1) {//成功就可以通讯了
                        // console.log('lian接成功');
                    } else {
                        console.log(res.data.msg)
                    }
                })
                .catch((err) => {
                    console.log('网络错误')
                });
            } else if (result.code == 1) {//等于1开始通讯
                // self.show_time(result, (self.state.data.length - 1));
                if (result.from_user == 1) {//用户的消息
                    //判断用户是否在左侧列表，不在就添加
                    let oldlist = self.state.chatlist;//现有的列表
                    let newid = result.from_uid;//客户的id
                    let tempposition = oldlist.findIndex(item => newid == item.from_uid);//看这个消息的用户在列表中的索引位置，为-1即为没有找到，就是新用户。
                    if (tempposition == -1) {//左侧列表没有这个人
                        result.status = 0;
                        oldlist.unshift(result);//把消息插到列表的最前边
                    } else {//左侧列表有该客户
                        let savenews = result;
                        if (result.from_uid == self.state.kh_id) {//新消息和右侧为同一个人
                            savenews.status = 1;//小红点
                            oldlist.splice(tempposition, 1);
                            oldlist.unshift(savenews);
                            // now_item=savenews;
                            this.setState({
                                bgcolor:result.from_uid,
                            });
                            //如果右侧发来的和新消息是同一个人，就刷新条天记录
                            let newdata = self.state.data;
                            newdata.push(result);
                            self.setState({
                                data: newdata,
                            });
                            $(".show_chat").scrollTop($(".show_chat")[0].scrollHeight);
                        } else {//不是同一个人设为未读
                            savenews.status = 0;//小红点
                            oldlist.splice(tempposition, 1);
                            oldlist.unshift(savenews);
                        }
                    }
                    self.setState({
                        chatlist: oldlist,//更新列表
                    });
                } else if (result.from_user == 0) {//客服消息
                    let newdata = self.state.data;
                    newdata.push(result);
                    self.setState({
                        data: newdata,
                    });
                    $(".show_chat").scrollTop($(".show_chat")[0].scrollHeight);
                }
            } else if (result.code == -2) {
                console.log('链接失败,请重新连接')
            }
        });
        ws.addEventListener('error', (e) => {
            // console.log(e.message);
        });
        ws.addEventListener('close', (e) => {
            console.log('关闭连接');
        });
    }
    //设定价格的弹出框是否显示
    showModal = () => {
        this.setState({
            visible: true,
        });
        this.visible = true;
    };
    //获取最低价
    getLowestPrice() {
        axios.post('https://api.zishahui.vip/admin-user/lowest-price', {
            goods_id: this.pre_good.message.split(',')[0],
            token: user.token,
            user_id: user.id,
        })
            .then((res) => {
                if (res.data.code == 1) {
                    this.setState({
                        lowestprice: res.data.data
                    })
                } else if (res.data.code == -2) {
                    this.props.history.push({
                        pathname: '/',
                    })
                } else {
                    alert(res.data.msg)
                }
            })
    };
    //设置价格
    handelChange = (e) => {
        this.setState({
            price: e.target.value,
        });
    };
    //确定按钮
    handleOk = () => {
        let {price} = this.state;
        if (!price) {
            alert('请输入价格');
            return
        }
        if (price >= parseFloat(this.state.lowestprice) || this.state.lowestprice === null) {
            axios.post('https://api.zishahui.vip/product/sure-price', {
                user_id: this.state.kh_id,
                product_id: this.pre_good.message.split(',')[0],
                price: price,
                customer_id: user.id,
                token: user.token,
            })
                .then((res) => {
                    if (res.data.code == 1) {
                        let message = this.pre_good.message.split(',')[0] + ',' + this.pre_good.message.split(',')[1] + ',' + this.pre_good.message.split(',')[2] + ',' + price;
                        this.sendContent(3, message);
                        this.setState({
                            visible: false,
                            price: '',
                        });
                        this.visible = false;
                    } else if (res.data.code == -2) {
                        this.props.history.push({
                            pathname: '/',
                        })
                    } else {
                        console.log(res.data.msg)
                    }
                })
                .catch((err) => {
                    console.log('网络错误')
                });
        } else {
            alert('不能低于最低价')
        }
    };
    //取消按钮
    handleCancel = () => {
        this.setState({
            visible: false,
            price: '',
        });
        this.visible = false;
    };
    //获取联系人列表
    getChatListData = () => {
        axios.post('https://api.zishahui.vip/admin-user/message-list', {
            token: user.token,
            user_id: user.id,
        })
        .then((res) => {
            if (res.data.code === 1) {
                now_item = res.data.data[0];
                this.setState({
                    chatlist: res.data.data,
                });
                this.getnew();
            } else if (res.data.code == -2) {
                this.props.history.push({
                    pathname: '/',
                })
            } else {
                console.log(res.data.msg)
            }
        })
        .catch((err) => {
            alert('网络错误')
        })
    };
    //获取聊天记录
    getChatContent = (type) => {
        page++;
        if (type) {
            page = 1;
        }
        now_item.status = 1;
        this.setState({
            kh_id: now_item.from_uid ? now_item.from_uid : now_item.id,
            bgcolor: now_item.from_uid,
        });
        return axios.post('https://api.zishahui.vip/admin-user/chat-record', {
            token: user.token,
            chat_id: now_item.from_uid ? now_item.from_uid : now_item.id,
            limit: limit,
            page: page,
            user_id: user.id,
        })
            .then((res) => {
                if (res.data.code == 1) {
                    this.setState({
                        ischat: res.data.status,
                    });
                    return res.data.data;
                } else if (res.data.code == -2) {
                    this.props.history.push({
                        pathname: '/',
                    })
                } else {
                    console.log(res.data.msg)
                }
            });
    };
    //第一次就展示20条
    getnew = async () => {
        let data = await this.getChatContent(true);
        if (data) {
            this.setState({
                data: data
            })
        }
        $('.show_chat').scrollTop($('.show_chat').prop('scrollHeight'));
    };
    //加载更多
    getmore = async () => {
        let data = await this.getChatContent();
        if (data.length != 0) {
            this.setState({
                data: data.concat(this.state.data)
            })
        } else {
            this.setState({
                more_text: '没有更多数据'
            })
        }
    };
    //删除聊天
    delChat = (e) => {
        axios.post('https://api.zishahui.vip/admin-user/delete-chat', {
            token: user.token,
            chat_id: e.from_uid,
            user_id: user.id,
        })
            .then((res) => {
                if (res.data.code == 1) {
                    this.getChatListData();
                } else if (res.data.code == -2) {
                    this.props.history.push({
                        pathname: '/',
                    })
                } else {
                    console.log(res.data.msg)
                }
            })
    };
    //输入文本内容
    handelChange2 = (e) => {
        this.setState({
            textareaValue: e.target.value,
        })
    };
    //判断是否显示时间
    show_time = (item, index) => {
        let now = this.state.data[index];
        if (index - 1 < 0) {
            return
        }
        let pre = this.state.data[index - 1];
        let now_time = new Date(now.create_time).getTime();
        let pre_time = new Date(pre.create_time).getTime();
        let now_time1 = now_time + '';
        let pre_time1 = pre_time + '';
        if (now_time1.length !== 13) {
            now_time = now_time * 1000;
        }
        if (pre_time1.length !== 13) {
            pre_time = pre_time * 1000;
        }
        // console.log(now_time);
        // console.log(pre_time);
        // console.log(now_time-pre_time);
        if (now_time - pre_time >= 3 * 60 * 1000) {
            return <span
                className='show_time'>{new Date(parseInt(now_time)).toLocaleString().replace(/年|月/g, "-").replace(/日/g, " ")}</span>
        } else {
            return
        }
    };
    //发送内容 参数type，message
    sendContent = (type, message) => {
        if (type == 1) {
            if (!message) {
                alert('发送内容不能为空');
                return false;
            }
        }
        let obj = {
            from_uid: user.id,
            to_uid: this.state.kh_id,
            shop_id: user.shop_id,
            type: type,
            message: message,
            tips: 1,
            nick_name:now_item.nick_name,//为了减轻服务器压力，用户的名称
            icon_path:now_item.icon_path,//为了减轻服务器压力，用户的头像
            // create_time:'2018-12-25 20:35:40',
        };
        ws.send(JSON.stringify(obj));
        this.setState({
            textareaValue: '',
        });
    };
    //搜索关键字
    searchkey = (e) => {
        this.setState({
            keyword: e.target.value,
        });
    };
    //快速清除
    quick_del = () => {
        this.setState({
            keyword: '',
            show_search: false,
        })
    };
    onfocus = () => {
        this.setState({
            quick_delte: true
        })
    };
    //回车发送/搜索
    onKeyup = (e, num) => {
        if (num == 0) {
            if (e.keyCode == 13) {
                this.getnew1()
            }
        } else if (num == 1) {
            if (e.keyCode == 13) {
                this.handleOk()
            }
        }
    };
    //换行
    onkeydown = (e) => {
        if (e.shiftKey && e.keyCode == 13) {
            e.preventDefault();
            this.setState({textareaValue: this.state.textareaValue + '\n'})
        } else if (e.keyCode == 13) {
            e.preventDefault();
            this.sendContent(1, this.state.textareaValue)
        }
    };
    //识别换行
    line = (message) => {
        let messages = message.replace(/(\n)|(\n)/g, "<br/>");
        return messages;
    };
    //搜索
    search = (type) => {
        page++;
        if (type) {
            page = 1;
        }
        // e.stopPropagation();
        this.setState({
            show_search: true,
        });
        return axios.post('https://api.zishahui.vip/admin-user/search-user', {
            key: this.state.keyword,
            limit: limit,
            page: page,
            token: user.token,
            user_id: user.id,
        })
            .then((res) => {
                if (res.data.code == 1) {
                    return res.data.code === 1 ? res.data.data : [];
                } else if (res.data.code == -2) {
                    this.props.history.push({
                        pathname: '/',
                    })
                } else {
                    console.log(res.data.msg)
                }
            })
    };
    getnew1 = async () => {
        let data = await this.search(true);
        if (data) {
            this.setState({
                customer: data
            })
        }
    };
    getmore1 = async () => {
        let data = await this.search();
        if (data.length != 0) {
            this.setState({
                customer: this.state.customer.concat(data)
            })
        } else {
            this.setState({
                more_text: '没有更多数据...'
            })
        }
    };
    //点击右边隐藏搜索结果框
    hide_search = () => {
        this.setState({
            show_search: false,
            keyword:''
        })
    };
    //点击搜索结果中的某个人并且拿到聊天记录，
    search_customer = (item) => {
        now_item = item;
        this.getnew();
        this.setState({
            show_search: false,
            keyword:'',
            bgcolor: item.id,
        });
        const oldlist = this.state.chatlist;
        const now_id = now_item.id;
        let tempposition = oldlist.findIndex(item => now_id == item.from_uid);//看这个消息的用户在列表中的索引位置，为-1即为没有找到，就是新用户
        // result.status=0;
        if (tempposition == -1) {
            item.from_uid = item.id;
            oldlist.unshift(now_item);//把消息插到列表的最前边
            this.getnew(true)
        } else {
            oldlist.unshift(oldlist[tempposition]);
            oldlist.splice(tempposition + 1, 1);
        }
        this.setState({
            chatlist: oldlist,//更新列表
        });
    };
    //客户消息
    kh_news = (item, index) => {
        if (item.type == 1) {
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item event'>
                        <div>
                            {
                                now_item.icon_path ?
                                    <img src={now_item.icon_path} alt="" className='chat_kh_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='chat_kh_img'/>
                            }
                            <div className='chat_item_text chat_item_text1'>
                                {item.message}
                            </div>
                        </div>
                    </div>
                </div>

            )
        } else if (item.type == 2) {//图片
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item event'>
                        <div>
                            {
                                now_item.icon_path ?
                                    <img src={now_item.icon_path} alt="" className='chat_kh_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='chat_kh_img'/>
                            }
                            <div className='chat_item_img chat_item_text1'>
                                <div className='kh_img_box'>
                                    <img src={item.message} alt="" className='ku_chat_img' onClick={() => {
                                        this.openimg(item.message)
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (item.type == 3) {//明码标价和询价
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item event'>
                        {
                            now_item.icon_path ?
                                <img src={now_item.icon_path} alt="" className='chat_kh_img'/>
                                :
                                <img src={Headportrait} alt="" className='chat_kh_img'/>
                        }
                        <div className='ask_price chat_item_text1'>
                            <img src={item.message.split(',')[2]} alt="" className='hot_img'/>
                            <div className='hot_info'>
                                <div className='hot_name'>壶名：{item.message.split(',')[1]}</div>
                                {
                                    item.message.split(',')[3] ?
                                        <div className='have_price'>{item.message.split(',')[3]}</div>
                                        :
                                        <div className='set_price_btn' onClick={() => {
                                            this.showModal();
                                            this.pre_good = item;
                                            this.getLowestPrice();
                                        }}>设定价格</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (item.type == 4) {//优惠
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item event'>
                        {
                            now_item.icon_path ?
                                <img src={now_item.icon_path} alt="" className='chat_kh_img'/>
                                :
                                <img src={Headportrait} alt="" className='chat_kh_img'/>
                        }
                        <div className='ask_price chat_item_text1'>
                            <img src={item.message.split(',')[2]} alt="" className='hot_img'/>
                            <div className='hot_info'>
                                <div className='have_discount_name'>{item.message.split(',')[1]}</div>
                                <div
                                    className='discount_text'>{item.message.split(',')[5] == 1 ? '[限时优惠]' : '[即将开始]'}</div>
                                <div>
                                    <div className='now_price'>￥{item.message.split(',')[3]}</div>
                                    <div className='old_price'>￥{item.message.split(',')[4]}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };
    //客服消息
    kf_news = (item, index) => {
        if (item.type == 1) {//客服文本
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item1 event'>
                        <div className='chat_item_child'>
                            {
                                user.icon_path ?
                                    <img src={user.icon_path} alt="" className='chat_kf_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='chat_kf_img'/>
                            }
                            <div className='sure_price_text chat_item_text2' id='kf_text'
                                 dangerouslySetInnerHTML={{__html: this.line(item.message)}}>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (item.type == 2) {//客服图片
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item1 event'>
                        <div className='chat_item_child'>
                            {
                                user.icon_path ?
                                    <img src={user.icon_path} alt="" className='chat_kf_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='chat_kf_img'/>
                            }
                            <div className='sure_price_img chat_item_text2'>
                                <div className='kh_img_box'>
                                    <img src={item.message} alt="" className='ku_chat_img' onClick={() => {
                                        this.openimg(item.message)
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else if (item.type == 3) {//定价
            return (
                <div key={index} className='container_box'>
                    {this.show_time(item, index)}
                    <div className='chat_item1 event'>
                        <div className='chat_item_child'>
                            {
                                user.icon_path ?
                                    <img src={user.icon_path} alt="" className='chat_kf_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='chat_kf_img'/>
                            }
                            <div className='sure_price chat_item_text2'>
                                <img src={item.message.split(',')[2]} alt="" className='hot_img'/>
                                <div className='hot_info'>
                                    <div className='hot_info'>
                                        <div className='hot_name'>{item.message.split(',')[1]}</div>
                                        <div className='have_price'>￥{item.message.split(',')[3]}</div>
                                    </div>
                                </div>
                                <div className='sure_text'>已定价</div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    };
    //上传图片
    uploadImg = () => {
        let self = this;
        let formData = new FormData();
        let files = document.getElementById('upload-img');
        let file = files.files[0];
        // console.log(file);//选中的本地的图片信息，包括大小，名称等
        formData.append('file', file);
        $.ajax({
            url: 'https://api.zishahui.vip/upload/upload-img',
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false,
            success: function (res) {
                // console.log(formData);就是一个formData对象。
                if (res.code == 1) {
                    self.sendContent(2, res.data)
                } else if (res.code == -2) {
                    this.props.history.push({
                        pathname: '/',
                    })
                } else {
                    console.log(res.msg)
                }
            }
        })
    };
    //点击图片打开新窗口
    openimg = (src) => {
        let newwin = window.open();
        let myimg = newwin.document.createElement("img");
        myimg.style.position = 'absolute';
        myimg.style.top = 0;
        myimg.style.bottom = 0;
        myimg.style.left = 0;
        myimg.style.right = 0;
        myimg.style.margin = 'auto';
        myimg.src = src;
        newwin.document.body.appendChild(myimg)
    };
    //设置小数
    setnum = (num) => {
        let reg = /^(\.*)(\d+)(\.?)(\d{0,2}).*$/g;
        if (reg.test(num)) {
            setTimeout(() => {
                this.setState({
                    price: num.replace(reg, '$2$3$4'),
                })
            }, 0)
        }
    };
    //编辑器
    editor = () => {
        let self = this;
        // let E = window.Editor;
        let editor = new Editor('#div1', '#div2');
        //配置菜单
        editor.customConfig.menus = [
            'emoticon',  // 表情
            'image',
        ];
        //配置表情
        editor.customConfig.emotions = [
            {
                // tab 的标题
                title: '默认',
                // type -> 'emoji' / 'image'
                type: 'image',
                // content -> 数组
                content: [
                    {
                        alt:"[坏笑]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4d/2018new_huaixiao_org.png"
                    },
                    {
                        alt:"[吐]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/08/2018new_tu_org.png"
                    },
                    {
                        alt:"[色]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9d/2018new_huaxin_org.png"
                    },
                    {
                        alt:"[微笑]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/e3/2018new_weixioa02_org.png"
                    },
                    {
                        alt:"[笑cry]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/4a/2018new_xiaoku_thumb.png"
                    },
                    {
                        alt:"[酷]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/c4/2018new_ku_org.png"
                    },
                    {
                        alt:"[衰]",
                        src: "http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a2/2018new_shuai_org.png"
                    },
                    {
                        src:"[哼]",
                        src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7c/2018new_heng_org.png",
                    },
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/30/2018new_sikao_org.png",alt:"[思考]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f6/2018new_nu_thumb.png",alt:"[怒]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/2018new_guzhang_thumb.png",alt:"[鼓掌]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a2/2018new_qian_thumb.png",alt:"[钱]",},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/2018new_kun_thumb.png",alt:"[困]",},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3e/2018new_tianping_thumb.png",alt:"[舔屏]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fd/2018new_baibai_thumb.png",alt:"[拜拜]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b0/2018new_xu_org.png",alt:"[嘘]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/43/2018new_zuohengheng_thumb.png",alt:"[左哼哼]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/2018new_yinxian_org.png",alt:"[阴险]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/7c/2018new_heng_thumb.png",alt:"[哼]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/30/2018new_sikao_org.png",alt:"[思考]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/f6/2018new_nu_thumb.png",alt:"[怒]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/6e/2018new_guzhang_thumb.png",alt:"[鼓掌]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/a2/2018new_qian_thumb.png",alt:"[钱]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3c/2018new_kun_thumb.png",alt:"[困]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/3e/2018new_tianping_thumb.png",alt:"[舔屏]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/fd/2018new_baibai_thumb.png",alt:"[拜拜]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/b0/2018new_xu_org.png",alt:"[嘘]",},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/43/2018new_zuohengheng_thumb.png",alt:"[左哼哼]"},
                    {src:"http://img.t.sinajs.cn/t4/appstyle/expression/ext/normal/9e/2018new_yinxian_org.png",alt:"[阴险]"},
                ]
            },
            {
                title: 'aa',
                // type -> 'emoji' / 'image'
                type: 'image',
                // content -> 数组
                content: [
                    {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/7a/shenshou_thumb.gif",
                        alt : "[草泥马]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/60/horse2_thumb.gif",
                        alt : "[神马]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/bc/fuyun_thumb.gif",
                        alt : "[浮云]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/c9/geili_thumb.gif",
                        alt : "[给力]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/f2/wg_thumb.gif",
                        alt : "[围观]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/70/vw_thumb.gif",
                        alt : "[威武]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6e/panda_thumb.gif",
                        alt : "[熊猫]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/81/rabbit_thumb.gif",
                        alt : "[兔子]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/bc/otm_thumb.gif",
                        alt : "[奥特曼]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/15/j_thumb.gif",
                        alt : "[囧]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/89/hufen_thumb.gif",
                        alt : "[互粉]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/c4/liwu_thumb.gif",
                        alt : "[礼物]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/ac/smilea_thumb.gif",
                        alt : "[呵呵]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/0b/tootha_thumb.gif",
                        alt : "[嘻嘻]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6a/laugh.gif",
                        alt : "[哈哈]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/14/tza_thumb.gif",
                        alt : "[可爱]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/af/kl_thumb.gif",
                        alt : "[可怜]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/a0/kbsa_thumb.gif",
                        alt : "[挖鼻屎]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/f4/cj_thumb.gif",
                        alt : "[吃惊]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6e/shamea_thumb.gif",
                        alt : "[害羞]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/c3/zy_thumb.gif",
                        alt : "[挤眼]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/29/bz_thumb.gif",
                        alt : "[闭嘴]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/71/bs2_thumb.gif",
                        alt : "[鄙视]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6d/lovea_thumb.gif",
                        alt : "[爱你]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/9d/sada_thumb.gif",
                        alt : "[泪]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/19/heia_thumb.gif",
                        alt : "[偷笑]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/8f/qq_thumb.gif",
                        alt : "[亲亲]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/b6/sb_thumb.gif",
                        alt : "[生病]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/58/mb_thumb.gif",
                        alt : "[太开心]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/17/ldln_thumb.gif",
                        alt : "[懒得理你]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/98/yhh_thumb.gif",
                        alt : "[右哼哼]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6d/zhh_thumb.gif",
                        alt : "[左哼哼]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/a6/x_thumb.gif",
                        alt : "[嘘]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/af/cry.gif",
                        alt : "[衰]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/73/wq_thumb.gif",
                        alt : "[委屈]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/9e/t_thumb.gif",
                        alt : "[吐]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/f3/k_thumb.gif",
                        alt : "[打哈欠]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/27/bba_thumb.gif",
                        alt : "[抱抱]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/7c/angrya_thumb.gif",
                        alt : "[怒]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/5c/yw_thumb.gif",
                        alt : "[疑问]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/a5/cza_thumb.gif",
                        alt : "[馋嘴]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/70/88_thumb.gif",
                        alt : "[拜拜]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/e9/sk_thumb.gif",
                        alt : "[思考]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/24/sweata_thumb.gif",
                        alt : "[汗]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/7f/sleepya_thumb.gif",
                        alt : "[困]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6b/sleepa_thumb.gif",
                        alt : "[睡觉]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/90/money_thumb.gif",
                        alt : "[钱]"
                    }, {
                        src: "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/0c/sw_thumb.gif",
                        alt : "[失望]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/40/cool_thumb.gif",
                        alt : "[酷]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/8c/hsa_thumb.gif",
                        alt : "[花心]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/49/hatea_thumb.gif",
                        alt : "[哼]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/36/gza_thumb.gif",
                        alt : "[鼓掌]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d9/dizzya_thumb.gif",
                        alt : "[晕]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/1a/bs_thumb.gif",
                        alt : "[悲伤]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/62/crazya_thumb.gif",
                        alt : "[抓狂]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/91/h_thumb.gif",
                        alt : "[黑线]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6d/yx_thumb.gif",
                        alt : "[阴险]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/89/nm_thumb.gif",
                        alt : "[怒骂]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/40/hearta_thumb.gif",
                        alt : "[心]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/ea/unheart.gif",
                        alt : "[伤心]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/58/pig.gif",
                        alt : "[猪头]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d6/ok_thumb.gif",
                        alt : "[ok]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d9/ye_thumb.gif",
                        alt : "[耶]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d8/good_thumb.gif",
                        alt : "[good]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/c7/no_thumb.gif",
                        alt : "[不要]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d0/z2_thumb.gif",
                        alt : "[赞]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/40/come_thumb.gif",
                        alt : "[来]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d8/sad_thumb.gif",
                        alt : "[弱]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/91/lazu_thumb.gif",
                        alt : "[蜡烛]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/6a/cake.gif",
                        alt : "[蛋糕]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/d3/clock_thumb.gif",
                        alt : "[钟]"
                    }, {
                        src : "http://img.t.sinajs.cn/t35/style/images/common/face/ext/normal/1b/m_thumb.gif",
                        alt : "[话筒]"
                    }

                ]
            },
            {
                // tab 的标题
                title: 'emoji',
                // type -> 'emoji' / 'image'
                type: 'emoji',
                // content -> 数组
                content: ['😃', '😄', '😁', '😆',]
            },
        ];
        //上传图片
        editor.customConfig.uploadImgServer = 'https://api.zishahui.vip/upload/upload-img';
        // 隐藏“网络图片”tab
        editor.customConfig.showLinkImg = false;
        //监听图片上传
        editor.customConfig.uploadImgHooks = {
            before: function (xhr, editor, files) {
                console.log(xhr);
                console.log(editor);
                console.log(files);
                // 图片上传之前触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，files 是选择的图片文件

                // 如果返回的结果是 {prevent: true, msg: 'xxxx'} 则表示用户放弃上传
                // return {
                //     prevent: true,
                //     msg: '放弃上传'
                // }
            },
            success: function (xhr, editor, result) {
                // 图片上传并返回结果，图片插入成功之后触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            fail: function (xhr, editor, result) {
                console.log(xhr);
                console.log(editor);
                console.log(result);
                // 图片上传并返回结果，但图片插入错误时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象，result 是服务器端返回的结果
            },
            error: function (xhr, editor) {
                console.log(xhr);
                console.log(editor);
                // 图片上传出错时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },
            timeout: function (xhr, editor) {
                // 图片上传超时时触发
                // xhr 是 XMLHttpRequst 对象，editor 是编辑器对象
            },

            // 如果服务器端返回的不是 {errno:0, data: [...]} 这种格式，可使用该配置
            // （但是，服务器端返回的必须是一个 JSON 格式字符串！！！否则会报错）
            customInsert: function (insertImg, result, editor) {
                // 图片上传并返回结果，自定义插入图片的事件（而不是编辑器自动插入图片！！！）
                // insertImg 是插入图片的函数，editor 是编辑器对象，result 是服务器端返回的结果

                // 举例：假如上传图片成功后，服务器端返回的是 {url:'....'} 这种格式，即可这样插入图片：
                let url = result.url;
                insertImg(url)

                // result 必须是一个 JSON 格式字符串！！！否则报错
            }
        };

        editor.create();
        if(this.state.ischat){
            editor.$textElem.attr('contenteditable', true)
        }else {
            editor.$textElem.attr('contenteditable', false)
        }

        document.getElementById('btn1').addEventListener('click', function () {
            text = editor.txt.html();
            // let reg=/<\/?.+?\/?>/g;
            let reg=/<(?!img).*?>/g;
            self.sendContent(1,text.replace(reg,''));
            // let reg = </?[p|P][^>]*>;
            // self.sendContent(1,text.replace(reg,''))
            editor.txt.clear()
        }, false)
    };
    //跳转设置页面
    gotosetting = () => {
        this.props.history.push({
            pathname:'/Setting'
        })
    };
    render() {
        const {chatlist, lowestprice, price, data, textareaValue, ischat, customer, more_text} = this.state;
        return (
            <div>
                <div className="container">
                    {/*左边布局*/}
                    <div className="left">
                        <div className='kf'>
                            {
                                user.icon_path ?
                                    <img src={user.icon_path} alt="" className='ke_img'/>
                                    :
                                    <img src={Headportrait} alt="" className='ke_img'/>
                            }
                            <div className='kf_info'>
                                <div className='nick_name'>{user.username}</div>
                                <div className='job_num'>工号：{user.id}</div>
                            </div>
                        </div>
                        <div className='search'>
                            <img src={search} alt="" className='search_btn' onClick={(e) => {
                                this.getnew1()
                            }}/>
                            <input type="text" id='con_people' placeholder='请输入昵称' value={this.state.keyword}
                                   className='search_box' onChange={this.searchkey.bind(this)} onKeyUp={(e) => {
                                this.onKeyup(e, 0)
                            }} onFocus={() => {
                                this.onfocus()
                            }}/>
                            {
                                this.state.keyword.length != 0 && this.state.quick_delte ?
                                    <img src={remove} alt="" className='quick_deletion' onClick={() => {
                                        this.quick_del()
                                    }}/>
                                    :
                                    null
                            }
                        </div>
                        <div className='contacts'>
                            {
                                chatlist.map((item, index) => {
                                    return (
                                        <div className={this.state.bgcolor === item.from_uid ? 'item1' : 'item'}
                                             key={index} onClick={() => {
                                            now_item = item;
                                            this.getnew(true);
                                        }}>
                                            <div className='item_left'>
                                                {
                                                    item.icon_path ?
                                                        <img src={item.icon_path} alt="" className='ke_img'/>
                                                        :
                                                        <img src={Headportrait} alt="" className='ke_img'/>
                                                }
                                                {
                                                    item.status == 0 ?
                                                        <div className='no_read'></div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <div className='kh_name'>{item.nick_name}</div>
                                            {
                                                item.create_time ?
                                                    <div
                                                        className='kh_time'>{common.analysisTime(item.create_time)}</div>
                                                    :
                                                    null
                                            }
                                            <img src={del} alt="" className='delete' onClick={() => {
                                                this.delChat(item)
                                            }}/>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/*<Link className='setting' to='/Setting'>*/}
                        <div className='setting' onClick={()=>{this.gotosetting()}}>
                            <img src={setting} alt=""/>
                            <div className='setting_text'>
                                <div style={{color: '#8498AD'}}>个人设置</div>
                            </div>
                        </div>
                        {/*</Link>*/}
                    </div>
                    {/*右边布局*/}
                    <div className='right' onClick={() => {
                        this.hide_search()
                    }}>
                        <div className='right_top'>{now_item.nick_name}</div>
                        <div className='right_mid'>
                            <div className='chat_content'>
                                <div className='show_chat'>
                                    <div className='morenews' onClick={() => {
                                        this.getmore()
                                    }}>{more_text}</div>
                                    {
                                        data.map((item, index) => {
                                            if (item.type == 10) {
                                                return (
                                                    <div className='container_box' key={index}>
                                                        <span className='show_time'>{item.message}</span>
                                                    </div>
                                                )
                                            } else {
                                                if (item.from_user == 1) { //客户
                                                    return (this.kh_news(item, index));
                                                } else if (item.from_user == 0) {//客服
                                                    return (this.kf_news(item, index));
                                                }
                                            }
                                        })
                                    }
                                </div>
                                {/*输入部分*/}
                                <div className='chat_input'>
                                    <div style={{width: '100%', paddingLeft: 5, height: '19%', position: 'relative'}}>
                                        {/*<img src={expression} alt="" className='expression'/>*/}
                                        <input type="file" name='file' id="upload-img" className='picture1'
                                               onChange={() => {
                                                   this.uploadImg()
                                               }}/>
                                        <img src={picture} alt="" className='picture'/>
                                    </div>

                                    {
                                    ischat?
                                    <textarea id='replay' className='replay' value={textareaValue} onChange={this.handelChange2.bind(this)} onKeyDown={(e)=>{this.onkeydown(e)}} placeholder='请输入'>

                                    </textarea>
                                    :
                                    <textarea  id='replay' className='replay' placeholder='此用户正在被其他客服接待，暂时无法聊天' readOnly>
                                    </textarea>
                                    }
                                    <Button type="primary" className='send_btn' onClick={() => {
                                        this.sendContent(1, this.state.textareaValue)
                                    }}>发送</Button>
                                </div>


                                {/*<div id="div1" className="toolbar">*/}
                                {/*</div>*/}
                                {/*<div id="div2" className="text">*/}

                                {/*</div>*/}
                                {/*<Button type="primary" id='btn1' className='send_btn1' onClick={() => {*/}

                                {/*}}>发送</Button>*/}
                            </div>
                        </div>
                    </div>
                    {/*搜索布局*/}
                    {
                        this.state.show_search &&
                        <div className='search_result'>
                            <div className='search_result_title'>搜索结果</div>
                            <div>
                                {
                                    customer.length != 0 ?
                                        customer.map((item, index) => {
                                            return (
                                                <div className='search_item' key={index} onClick={() => {
                                                    this.search_customer(item)
                                                }}>
                                                    {
                                                        item.icon_path ?
                                                            <img src={item.icon_path} alt="" className='search_img'/>
                                                            :
                                                            <img src={Headportrait} alt="" className='search_img'/>
                                                    }
                                                    <div className='search_text'>{item.nick_name}</div>
                                                </div>
                                            )
                                        })
                                        :
                                        <div className='no_result'>
                                            暂无结果
                                        </div>
                                }
                                {
                                    customer.length >= 1 &&
                                    <div className='no_more' onClick={() => {this.getmore1()}}>{this.state.more_text}</div>
                                }
                            </div>
                        </div>
                    }
                    {
                        this.visible &&
                        <Modal
                            title="设定价格"
                            visible={this.state.visible}
                            onOk={this.handleOk}
                            onCancel={this.handleCancel}
                            style={{textAlign: 'center', width: 362, height: 211,}}
                            cancelText='取消'
                            okText='确定'
                            destroyOnClose={true}//默认为false不销毁内容
                        >
                            <div>
                                <div className='price_box'>
                                    <div style={{float: 'left'}}>￥</div>
                                    <input
                                        type="number"
                                        onChange={this.handelChange.bind(this)}
                                        value={price}
                                        placeholder='请输入价格'
                                        onKeyUp={(e) => {
                                            this.onKeyup(e, 1);
                                            this.setnum(this.state.price)
                                        }}
                                    />
                                    <div style={{float: 'right'}}>元</div>
                                </div>
                                {
                                    this.state.lowestprice &&
                                    <div style={{
                                        display: 'inline-block',
                                        marginTop: 10,
                                        fontSize: 16,
                                        color: '#666666'
                                    }}>
                                        建议最低价￥
                                        <div style={{display: 'inline-block'}}>{lowestprice ? lowestprice : '无'}</div>
                                    </div>
                                }

                            </div>
                        </Modal>
                    }
                </div>
            </div>
        );
    }
}

export default App;



