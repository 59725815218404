import React, { Component } from 'react';
import { BrowserRouter as Router,Route,} from 'react-router-dom';
import Basic from './pages/Basic';
import Login from './pages/Login';
import Setting from './pages/Setting';
class App extends Component {
    render() {
        return (
        <Router>
            <div>
                <Route path="/" component={Login} exact/>
                <Route path="/Basic" component={Basic}/>
                <Route path="/Setting" component={Setting}/>
            </div>
        </Router>
        );
    }
}

export default App;
// import React from 'react';
// import ReactDOM from 'react-dom';
// import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom'
// class A extends React.Component{
//     constructor(props){
//         super(props)
//     }
//     render(){
//         return (
//             <div>
//                 Component A
//                 <Switch>
//                     {/*在route里还能写JSX*/}
//                     <Route exact path={`${this.props.match.path}`} render={(route) => {
//                         return <div>当前组件是不带参数的A</div>
//                     }}/>
//                      {/*a/sub 要写在 a/:id的前面，因为sub也有可能被当作id*/}
//                     <Route path={`${this.props.match.path}/sub`} render={(route) => {
//                         return <div>当前组件是Sub</div>
//                     }}/>
//                     <Route path={`${this.props.match.path}/:id`} render={(route) => {
//                         return <div>当前组件是带参数的A, 参数是：{route.match.params.id}</div>
//                     }}/>
//                 </Switch>
//             </div>
//         )
//     }
// }
// class B extends React.Component{
//     constructor(props){
//         super(props)
//     }
//     render(){
//         return <div>Component B</div>
//     }
// }
// class Wrapper extends React.Component{
//     constructor(props){
//         super(props)
//     }
//     render(){
//         return (
//             <div>
//                 <Link to="/a">组件A</Link>
//                 <br/>
//                 <Link to="/a/123">带参数的组件A</Link>
//                 <br/>
//                 <Link to="/b">组件B</Link>
//                 <br/>
//                 <Link to="/a/sub">/a/sub</Link>
//                 {this.props.children}
//             </div>
//         );
//     }
// }
// class App extends React.Component {
//     render(){
//         return(
//             <Router>
//                 <Wrapper>
//                     <Route path="/a" component={A}/>
//                     <Route path="/b" component={B}/>
//                 </Wrapper>
//             </Router>
//         )
//     }
// }
// export default App
