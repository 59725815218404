import React, { Component } from 'react';
import './login.css';
import '../../node_modules/antd/dist/antd.css';
import axios from 'axios';

let timer;
class Login extends Component {
    constructor(props){
        super(props);
        this.state={
            phone:'',
            yzm:'',//输入框的验证码
            time:'获取验证码',//倒计时的显示
        }
    }
    //获取手机号码
    getPhone = (e) => {
        this.setState({
            phone:e.target.value.replace(/[^0-9.]/g, ''),
        })
        // let val=e.target.value;
        // let reg=/^[1-9]\d*$/.test(val);
        // if(reg){
        //     this.setState({
        //         phone:val,
        //     })
        // }else {
        //     return false;
        // }
    };
    //获取输入框的验证码
    getyzm1 = (e) => {
        this.setState({
            yzm:e.target.value.replace(/[^0-9.]/g, ''),
        })
        // let val=e.target.value;
        // let reg=/^[1-9]\d*$/.test(val);
        // if(reg){
        //     this.setState({
        //         yzm:val,
        //     })
        // }else {
        //     return false;
        // }
    };
    //给手机发验证码
    getyzm = () => {
        if((/^[1][3,4,5,7,8][0-9]{9}$/.test(this.state.phone))){
            axios.post('https://api.zishahui.vip/mobile/send-sms',{
                phone:this.state.phone,
                type:2,
            })
            .then((res)=>{
                if(res.data.code==1){
                    this.times()
                }else if(res.data.code==-2){
                    this.props.history.push({
                        pathname:'/',
                    })
                }else {
                    console.log(res.data.msg)
                }
            });
        }else{
            alert('手机号码有误')
        }
    };
    //倒计时
    times = () => {
        let num1=60;
        timer=setInterval(()=>{
            num1--;
            if(num1<0){
                this.setState({
                    time:"获取验证码",
                });
                clearInterval(timer);
                num1=60;
            }else{
                this.setState({
                    time:num1+"s",
                });
            }
        },1000)
    };
    //登录
    login = () => {
        if(this.state.phone.length==0){
            alert('请输入手机号码');
            return
        }else if(this.state.phone.length!=11){
            alert('手机号码有误');
            return
        }
        if(this.state.yzm.length==0){
            alert('请输入验证码');
            return
        }else if(this.state.yzm.length!=6){
            alert('验证码有误');
            return
        }
        if(this.state.yzm.length===6&&(/^[1][3,4,5,7,8][0-9]{9}$/.test(this.state.phone))){
            // let history = this.context.router.history;
            axios.post('https://api.zishahui.vip/admin-user/code-login',{
                phone:this.state.phone,
                code:this.state.yzm,
            })
            .then((res)=>{
                if(res.data.code==1){
                    localStorage.setItem('user',JSON.stringify(res.data.data));
                    this.props.history.push({
                        pathname:'/Basic',
                    })
                }else if(res.data.code==-2){
                    this.props.history.push({
                        pathname:'/',
                    });
                }else {
                    console.log(res.data.msg)
                }
            });
        }else {
            alert('手机号码与验证码不匹配')
        }
    };
    //回车登录
    onKeyup = (e) =>{
        if(e.keyCode==13){
            this.login()
        }
    };

    render() {
        const {phone,yzm}=this.state;
        return (
            <div className='container'>
               <div className='login_top'>
                   <div className='log_title'>紫砂汇客服系统</div>
                   <div className='line'> </div>
                   <div className='log_btn'>登录</div>
               </div>
                <div className='log_bottom'>
                    <div className='title'>登录</div>
                    <div className='log_phone'>
                        <div className='log_label'>手机号码</div>
                        <input type="text" placeholder='请输入手机号' onChange={(e)=>{this.getPhone(e)}} value={phone} maxLength="11" onKeyUp={(e)=>{this.onKeyup(e)}} required />
                    </div>
                    <div className='log_yzm'>
                        <div className='log_label'>验证码</div>
                        <input type="text" placeholder='请输入验证码' onChange={this.getyzm1.bind(this)} value={yzm} maxLength="6" onKeyUp={this.onKeyup.bind(this)} required/>
                        <div className='yzm' onClick={()=>{this.getyzm()}}>{this.state.time}</div>
                    </div>
                    <div className='log_button' onClick={()=>{this.login()}}>
                        登录
                    </div>
                </div>
            </div>
        );
    }
}

export default Login;

