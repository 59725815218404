import React, { Component } from 'react';
import './setting.css';
import cancel from '../image/return.png';
import exit from '../image/signout.png';
import edit from '../image/edit.png';
import Headportrait from '../image/Headportrait.png';
import {Link} from 'react-router-dom';
import { Modal } from 'antd';
import '../../node_modules/antd/dist/antd.css';
import $ from  'jquery';
import axios from  'axios';
let user=JSON.parse(localStorage.getItem('user'));
class Login extends Component {
    state={
        visible:false,//昵称
        name:'',
        visible1:false,//性别
        sex:'',
        img:'',
    };
    visible=false;
    visible1=false;
    componentWillMount(){
        user=JSON.parse(localStorage.getItem('user'));
        this.setState({
            name:user.username,
            sex:user.sex,
            img:user.icon_path,
        })
    }
    //修改昵称
    showModal = () => {
        this.setState({
            visible: true,
        });
        this.visible=true;
    };
    handelChange = (e) => {
        this.setState({
            name:e.target.value,
        })
    };
    update_name = () => {
        console.log(user);
        axios.post('https://api.zishahui.vip/admin-user/edit-name',{
            token:user.token,
            // user_id:user.id,
            username:this.state.name,
        })
        .then((res)=>{
            if(res.data.code===1){
                user.username=this.state.name;
                localStorage.setItem('user',JSON.stringify(user));
                this.setState({
                    visible:false,
                });
                this.visible=false
            }else if(res.data.code==-2){
                this.props.history.push({
                    pathname:'/',
                })
            }else {
                this.setState({
                    visible:false,
                });
                this.visible=false;
            }
        })
        .catch((err)=>{alert('网络错误')});
    };
    showModal1 = () => {
        this.setState({
            visible1: true,
        });
        this.visible1=true;
    };
    handelChangeSex = (e) => {
        this.setState({
            sex:e.target.value,
        })
    };
    update_sex = () => {
        axios.post('https://api.zishahui.vip/admin-user/edit-sex',{
            token:user.token,
            sex:this.state.sex,
            user_id:user.id,
        })
        .then((res)=>{
            if(res.data.code===1){
                user.sex=this.state.sex;
                localStorage.setItem('user',JSON.stringify(user));
                this.setState({
                    visible1:false,
                });
                this.visible1=false
            }else if(res.data.code===-2){
                this.props.history.push({
                    pathname:'/',
                })
            }else {
                console.log(res.data.msg);
            }
        })
        .catch((err)=>{alert('网络错误')})
    };
    handleCancel = () => {
        this.setState({
            visible: false,
            visible1: false,
            name:user.username,
            sex:user.sex,
        });
        this.visible=false;
        this.visible1=false;
    };
    uploadImg = () => {
        let self=this;
        let files = $("#head-icon").get(0).files[0]; //获取file控件中的内容
        let reader = new FileReader();
        reader.readAsDataURL(files);
        let formData = new FormData();
        formData.append("file", files);
        formData.append("user_id", user.id);
        formData.append("token", user.token);
        $.ajax({
            url:'https://api.zishahui.vip/admin-user/upload-head',
            type:'POST',
            data:formData,
            processData : false,
            contentType : false,
            success:function(res){
                if(res.code == 1){
                    self.setState({
                        img:res.data,
                    });
                    user.icon_path=self.state.img;
                    localStorage.setItem('user',JSON.stringify(user));
                }else if(res.code==-2){

                }else{
                    console.log(res.msg);
                }
            }
        })
    };
    render() {
        return (
            <div className='container'>
                <div className='container_top'>
                    <Link className='top_left1' to='/Basic'>
                        <img src={cancel} alt="" className='top_left_img'/>
                        <div className='top_left'>个人设置</div>
                    </Link>
                    <div className='head_img_box'>
                        {
                            user.icon_path?
                                <img src={this.state.img} alt="" className='head_img' id=""/>
                                :
                                <img src={Headportrait} alt="" className='head_img' id='mPhoto'/>
                        }
                        <div className='upload_text'>上传头像</div>
                        <input type="file" id="head-icon" onChange={()=>{this.uploadImg()}} className='change_head_img'/>
                    </div>
                    {/*<Link className='top_right' to='/'>*/}
                        {/*<img src={exit} alt=""/>*/}
                        {/*<div className='top_right'>退出登录</div>*/}
                    {/*</Link>*/}
                    <Link className='top_left1' to='/'>
                        <img src={exit} alt="" className='top_left_img'/>
                        <div className='top_left'>退出登录</div>
                    </Link>
                </div>
                <div className='container_bottom'>
                    <div className='bottom_box'>
                        <div className='pre_line'>
                            <div className='pre_one'>昵称</div>
                            <div className='pre_two'>{this.state.name}</div>
                            <div style={{width:230,float:'right'}}>
                                <img src={edit} alt="" className='pre_img' onClick={()=>{this.showModal()}}/>
                            </div>
                        </div>
                        <div className='pre_line'>
                            <div className='pre_one'>性别</div>
                            <div className='pre_two'>{user.sex==1?'男':'女'}</div>
                            <div style={{width:230,float:'right'}}>
                                <img src={edit} alt="" className='pre_img' onClick={()=>{this.showModal1()}}/>
                            </div>
                        </div>
                        <div className='pre_line'>
                            <div className='pre_one'>客服工号</div>
                            <div className='pre_two'>{user.id}</div>
                        </div>
                        <div className='pre_line'>
                            <div className='pre_one'>手机号码</div>
                            <div className='pre_two'>{user.phone}</div>
                        </div>
                    </div>
                </div>
                {
                    this.visible &&
                    <Modal
                        title="修改昵称"
                        visible={this.state.visible}
                        onOk={this.update_name}
                        onCancel={this.handleCancel}
                        style={{textAlign: 'center',zIndex:999}}
                        cancelText='取消'
                        okText='确定'
                    >
                        <input
                            type="text"
                            onChange={this.handelChange.bind(this)}
                            defaultValue={this.state.name}
                            className='update_nickname'
                        />
                    </Modal>
                }
                {
                    this.visible1 &&
                        <Modal
                            title="修改性别"
                            visible={this.state.visible1}
                            onOk={this.update_sex}
                            onCancel={this.handleCancel}
                            style={{textAlign: 'center',zIndex:999}}
                            cancelText='取消'
                            okText='确定'
                        >
                            <div className='update_box'>
                                <div className='user_sex'>
                                    <input name="sex" type="radio" onChange={this.handelChangeSex.bind(this)} value='1'/>男
                                </div>
                                <div className='user_sex'>
                                    <input name="sex" type="radio" onChange={this.handelChangeSex.bind(this)} value='2'/>女
                                </div>
                            </div>
                        </Modal>
                }
            </div>
        );
    }
}

export default Login;

