import md5 from "./md5";
/**
 * 通用底层JS
 * */
const toolClass = {}
/**
 *  验证手机号
 */

toolClass.isPhone = function (str) {
    let myreg = /^[1][3,4,5,7,8][0-9]{9}$/;
    if (!myreg.test(str)) {
        return false;
    } else {
        return true;
    }
}
/**
 * analysisTime 解析时间戳
 * @param {Number} timeStamp
 */
toolClass.analysisTime = function (timeStamp, type) {
//  	console.log(timeStamp);
    let current_time = new Date().getTime();
    let differ_time = parseInt(current_time / 1000) - timeStamp;
    let minutes1 = 60;
    let hours = 3600;
    let day1 = 86400;
    let yesterday = 86400 * 2;
    let qianTian = 86400 * 3;
    let time = new Date(timeStamp * 1000);
//  	console.log(time);
//  	console.log("天："+time.getDate());
//  	console.log("月："+(time.getMonth()+1));
    if (type) {
        return '' + time.getFullYear() + "年" + (time.getMonth() + 1) + "月" + time.getDate() + "日"
    }
    if (differ_time < minutes1) {
        return "刚刚"
    }
    if (differ_time < hours) {
        return parseInt(differ_time / 60) + "分钟前";
    }
    if (differ_time < day1) {
        if (time.getDate() != (new Date(current_time)).getDate()) {
            return "昨天";
        }
        var minutes = time.getMinutes();
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        return time.getHours() + ":" + minutes;
    }
    if (differ_time < yesterday) {
        return "昨天"
    }
    if (time.getFullYear() == (new Date(current_time).getFullYear())) {
        return '' + (time.getMonth() + 1) + "-" + time.getDate()
    }
    return '' + time.getFullYear() + "年" + (time.getMonth() + 1) + "月" + time.getDate() + "日"
}
toolClass.timelong = function (time) {
    if (time < 3600) {
        let minutes = Math.floor(time / 60);
        let seconds = time % 60;
        if (minutes < 0) {
            minutes = 0;
        }
        if (seconds < 0) {
            seconds = 0;
        }
        return (minutes < 10 ? '0' + minutes : minutes) + ":" + (seconds < 10 ? '0' + parseInt(seconds) : parseInt(seconds));
    }
}

toolClass.dateSurplus = function (time){
    let day1 = 86400;
    let dateNum = Math.floor(time/day1);
    let seconds = time % day1;
    let hours = parseInt(seconds/3600);
    return dateNum + '天' + hours + '小时'
}

toolClass.timeChange = function (dateString, formdate) {
    if (dateString == null || dateString == '') {
        return '';
    }
    // new Date('');传入毫秒数,也可以得到普通的时间,再对date处理
    let date = new Date(parseInt(dateString * 1000));
    //获取年份,月份,天数,小时数,分钟数,小于10的显示01-09
    let year = date.getFullYear();
    let month = date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1;
    let currentDate = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
    let hours = date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
    let minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    let second = date.getSeconds() <10 ? "0" + date.getSeconds() : date.getSeconds();
    if (formdate == null || formdate == "yyyy-mm-dd HH:mm") {
        if (year == (new Date()).getFullYear()) {
            return month + "-" + currentDate + " " + hours + ":" + minutes;
        } else {
            return year + "-" + month + "-" + currentDate + " " + hours + ":" + minutes;
        }
    } else if (formdate == "yyyy-mm-dd") {
        return year + "-" + month + "-" + currentDate;
    } else if (formdate == "yyyy-mm") {
        return year + month;
    } else if (formdate == "mm-dd") {
        return month + "-" + currentDate;
    } else if (formdate == "HH:mm") {
        return hours + ":" + minutes;
    } else if (formdate == "mm分ss秒") {
        return minutes + "分" + second + '秒';
    } else if (formdate == "HH时mm分") {
        return hours + "小时" + minutes + "分";
    } else if (formdate == "dd天HH时") {
        return currentDate + "天" + hours + "小时";
    } else {
        return "";
    }
}
toolClass.timeToStamp = function (time) {
    let date1 = new Date(time).getTime();
    return date1
}

toolClass.trim = function (str) {
    return str.replace(/\s+/g, "");
}
toolClass.dataURLtoBlob = function (dataurl) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: mime });
}
/**
 * 参数加密    调用接口
 * @param arr[] params
 */
toolClass.mySign =  function(params){
    //排序
    let param = toolClass.ksort(params);
    //转换为字符串
    let sign = '';
    for(let key in param){
        sign += key + "=>" + param[key] + "&";
    }
    //拼接固定字符
    sign += "zishahui";
    //md5加密
    sign = md5(sign);
    return sign;
}

/**
 * php ksort
 * @param {Object} inputArr
 * @param {Object} sort_flags
 */
toolClass.ksort = function(inputArr, sort_flags) {
    // original by: GeekFG (http://www.0-php.com)
    let tmp_arr = {},
        keys = [],
        sorter, i, k, that = this,
        strictForIn = false,
        populateArr = {};

    switch (sort_flags) {
        case 'SORT_STRING':
            // compare items as strings
            sorter = function (a, b) {
                return that.strnatcmp(a, b);
            };
            break;
        case 'SORT_LOCALE_STRING':
            // compare items as strings, original by the current locale (set with  i18n_loc_set_default() as of PHP6)
            let loc = this.i18n_loc_get_default();
            sorter = this.php_js.i18nLocales[loc].sorting;
            break;
        case 'SORT_NUMERIC':
            // compare items numerically
            sorter = function (a, b) {
                return ((a + 0) - (b + 0));
            };
            break;
        // case 'SORT_REGULAR': // compare items normally (don't change types)
        default:
            sorter = function (a, b) {
                var aFloat = parseFloat(a),
                    bFloat = parseFloat(b),
                    aNumeric = aFloat + '' === a,
                    bNumeric = bFloat + '' === b;
                if (aNumeric && bNumeric) {
                    return aFloat > bFloat ? 1 : aFloat < bFloat ? -1 : 0;
                } else if (aNumeric && !bNumeric) {
                    return 1;
                } else if (!aNumeric && bNumeric) {
                    return -1;
                }
                return a > b ? 1 : a < b ? -1 : 0;
            };
            break;
    }

    // Make a list of key names
    for (k in inputArr) {
        if (inputArr.hasOwnProperty(k)) {
            keys.push(k);
        }
    }
    keys.sort(sorter);

    // BEGIN REDUNDANT
    this.php_js = this.php_js || {};
    this.php_js.ini = this.php_js.ini || {};
    // END REDUNDANT
    strictForIn = this.php_js.ini['phpjs.strictForIn'] && this.php_js.ini['phpjs.strictForIn'].local_value && this.php_js
        .ini['phpjs.strictForIn'].local_value !== 'off';
    populateArr = strictForIn ? inputArr : populateArr;

    // Rebuild array with sorted key names
    for (i = 0; i < keys.length; i++) {
        k = keys[i];
        tmp_arr[k] = inputArr[k];
        if (strictForIn) {
            delete inputArr[k];
        }
    }
    for (i in tmp_arr) {
        if (tmp_arr.hasOwnProperty(i)) {
            populateArr[i] = tmp_arr[i];
        }
    }

    return strictForIn || populateArr;
}

export default toolClass;
	